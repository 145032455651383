import { sendPostRequest } from '../helper/HttpHelper'
import { camelCaseToTitle, sortObjectByKeyOrder } from './Helper';
import { useEffect, useContext, useState } from 'react'
import React from 'react';
import { AuthContext } from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import {Modal} from './Modal'
import { LoadingOverlay, PdfFileElement } from "../component/Common";
import { ListFilter } from './ListFilter';
import { Label, Field, Switch } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

function BuildList({listJSON, FeedUI}) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()
  const {token} = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedItem, setSelectedItem] = useState('')
  const [actionIndex, setActionIndex] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({title: '', content: '', action: '', button_text: ''})
  const [httpLoading, setHttpLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
 
  const handleLogout = () => {
    authCtx.logout()
    navigate('/login')
  }
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...filteredList].sort((a, b) => {
        if (a['ATTR1'][sortConfig.key] < b['ATTR1'][sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a['ATTR1'][sortConfig.key] > b['ATTR1'][sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredList;
  }, [filteredList, sortConfig]);
  
  const getCompleteList = () => {
    return list;
  }
  // To display the confirmation box
  function handleConfirmation(item, action_index) {
    //console.log('BuildList - handleConfirmation - ' + item)
    setSelectedItem(item)   
    setActionIndex(action_index)  
    setMesg({title: listJSON.body.action[action_index].title, content: listJSON.body.action[action_index].content, action: handleApproval, button_text: listJSON.body.action[action_index].action_text})
    setOpen(true)
  }

  async function handleApproval(data, actionIndex){
    //console.log('BuildList - handling approval - ' + data)
    try{
      let res = await sendPostRequest({'url': listJSON.body.action[actionIndex].endpoint, 'payload': {'otherProps': data?.ATTR1, 'meta':{token: token}, 'method': listJSON.body.action[actionIndex].method }, 'setLoading':setHttpLoading, 'handleLogout':handleLogout}); 
      //console.log(res)
      if(res.status === 'error'){
        console.error(res.message)
        return
      }
      setOpen(false)
      setSelectedItem('')
      if(listJSON.body.action[actionIndex].navigate)
        navigate(listJSON.body.action[actionIndex].navigate)
      else
        window.location.reload()
    }catch(e){
      console.error(e)
    }
  }
  
  function getSelectedItem(){
    return selectedItem
  }
  function getActionIndex(){
    return actionIndex
  }
  function  renderTemplate(name){
    switch(name){
        case 'renderTableList':
            return renderTableList()
        case 'renderFeedList':
            return renderFeedList()
        case 'renderCompactList':
            return renderCompactList()
        default:
            return renderTableList()
    } 
  }        

  useEffect(() => {
    const fetchData = async () => {
      try {
        //console.log("BuildList - UseEffect - Fetching data from URL - " + listJSON.fetch.endpoint)
        const params = {
          method: listJSON?.fetch?.method,
          data: listJSON?.fetch?.data,
          meta: token?.eid ? {
            eid: token.eid,
            username: token.username,
            token: token,
            display_name: token.display_name
          } : {}
        };
    
        const res = await sendPostRequest({'url': listJSON.fetch.endpoint, 'payload': params, 'setLoading':setHttpLoading, 'handleLogout':handleLogout});
        //console.log(res);
        
        const filter = listJSON.body?.filter;
        if (filter) {
          res.data = res.data.filter(item => {
            for (const key in filter) {
              if (item.ATTR1[key] !== filter[key]) {
                return false;
              }
            }
            return true;
          });
        }

        res.data = res.data.map(item => {
          if (listJSON.body.key_order && item.ATTR1) {
            item.ATTR1 = sortObjectByKeyOrder(item.ATTR1, listJSON.body.key_order);
          }
          return item;
        });
        
        if (res.status === 'error') {
          console.error(res.message);
          return;
        }
        setList(res.data);
        setFilteredList(res.data)
        //console.log("Setting List - " + JSON.stringify(res.data))
      } catch (e) {
        console.error(e);
      }
    };
    fetchData()
  }, [])
  return renderTemplate(listJSON?.body?.template)
  
  function ToggleButton({enabled, setEnabled, filterLabel}) {
    return (
          <Field className="flex items-center">
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
              <Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">{filterLabel}</span>{' '}
              </Label>
        </Field>
    )
  }
  function ListHeader({showFilter, setShowFilter, filterLabel}) {

    return (
        <div className="border-b border-gray-200 bg-white px-4 py-3 mb-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">{listJSON.body.title}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {listJSON.body.description}
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <ToggleButton enabled={showFilter} setEnabled={setShowFilter} filterLabel={filterLabel}></ToggleButton>
          </div>
          </div>
        </div>
    )
  }
  /* function ListFilter(){
    return (listJSON?.body?.filter_form)?CreateForm({'formJSON': listJSON.body.filter_form, 'stack': 'vertical', 'dataSetter':setList}):<></>
  }*/
  
  function renderTableList(){
      return (
        <div className="px-4 sm:px-6 lg:px-8">
          {(open) && <Modal title={mesg.title} content={mesg.content} action={mesg.action} getActionIndex={getActionIndex} button_text={mesg.button_text} getData={getSelectedItem} closeModal={() => setOpen(false)}></Modal>}
          <ListHeader showFilter={showFilter} setShowFilter={setShowFilter} filterLabel="Show Filter"/>
          {showFilter && <ListFilter getCompleteList={getCompleteList} filterBy={listJSON.body?.filterBy} setList={setFilteredList} />}
          <div className="mt-4 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {(httpLoading)?<LoadingOverlay/>:
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-4 px-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"></th>  
                        {listJSON.body.key_order.map((key, index) => (
                            <th key={index} 
                                scope="col" 
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                onClick={() => handleSort(key)}
                            >
                          {sortConfig.key === key ? (
                              sortConfig.direction === 'ascending' ? (
                                <ChevronUpIcon className="inline ml-2 h-5 w-5" />
                              ) : (
                                <ChevronDownIcon className="inline ml-2 h-5 w-5" />
                              )
                            ) : null}
                              {camelCaseToTitle(key)}
                            </th>
                        ))}
                      </tr>
      
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {sortedData && sortedData.length > 0 && sortedData.map((item, index) => (
                        <tr key={index} className="even:bg-gray-50">
                         <td key="45" className="py-4 px-4 pr-3">
                         <span className="inline-block size-8 overflow-hidden rounded-full bg-gray-100">
                            <svg fill="currentColor" viewBox="0 0 24 24" className="size-full text-gray-300">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                         </td>
                         {listJSON.body.key_order.map((key, index) => {
                              //if (item.ATTR1[key]) return null;
                              const prepend = listJSON.body?.value_prepend?.[key] ?? '';
                              const append = listJSON.body?.value_append?.[key] ?? '';
                              const value = listJSON.body?.format?.[key] ? listJSON.body.format[key](item.ATTR1[key]) : (item.ATTR1[key])?item.ATTR1[key]:'';
                              return key.includes("_file") ? (
                                (value)?<td key={index} className="whitespace-nowrap py-4 pl-4 pr-3">
                                        <PdfFileElement name={value[0]['fname']} size={value[0]['size']} fileUrl={value[0]['url']}></PdfFileElement>
                                      </td>:<td key={index}></td>
                              ) :
                              (
                                <td key={index} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                  {`${prepend} ${value} ${append}`}
                                </td>
                              );
                          })}
                          {listJSON.body.action && listJSON.body.action.map((action,index) => (
                            action.exclude && item.ATTR1[action.exclude] === "true" ? null : (
                              <td key={index} className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 space-x-2">
                                <a href="#" onClick={() => handleConfirmation(item, index)} className="text-indigo-600 hover:text-indigo-900">
                                  {action.action_text}
                                </a>
                              </td>
                            )
                          ))}
                        </tr>
                          
                      ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
      )
  }
  function renderFeedList(){
    function handleClick({url}) {
      navigate(url)
    }
    return (
        <>
        <ListHeader />
        {
            (httpLoading)?<LoadingOverlay/>:
              <ul
              role="list"
              className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
              >
                {list && list.length > 0 && list.map((item, index) => (
                    <FeedUI item={item} feedJSON={listJSON} handleClick={handleClick} ></FeedUI>
                ))}
              </ul>
        }
        </>
    )
  }
  //{noun, modified_datetime, verb, object}
  function renderCompactList() {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <ListHeader />
        <ListFilter />
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden md:rounded-lg">
                <ul className="divide-y divide-gray-200 bg-white">
                  {/* Example log entries */}
                  {list && list.length > 0 && list.map((item, index) => (
                    <FeedUI
                      key={index}
                      props={item}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

}
export { BuildList }