import { API_CONFIG } from '../../helper/HttpHelper'
import { CreateContainer } from '../../component/Container'
import { Gallery } from '../../component/Gallery'

function SupplierCompanyDetailScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Company Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'business_name', label: 'Business Name', error:['Please enter valid business name'], validator:['isNotEmpty'] },
            { type: 'FTComboBox', label: 'Key Products', field: 'key_products', options:[{l:'Feed', k:'feed'},{l:'Fertiliser', k:'fertiliser'},{l:'Growth & Nutrition', k:'growth_nutrition'},
                {l:'Pest & Disease', k:'pest_disease'},{l:'Equipments', k:'equipments'},{l:'Chemicals', k:'chemicals'},
                {l:'Irrigation', k:'irrigation'},{l:'Others', k:'others'}
             ]
            },
            { type: 'FTComboBox', label: 'Key Sectors', field: 'key_sectors', options:[{l:'Agriculture', k:'agriculture'},
                {l:'Aquaculture', k:'aquaculture'},{l:'Poultry', k:'poultry'},{l:'Dairy', k:'dairy'},{l:'Others', k:'others'}
             ]
            },
            { type: 'FTTextAreaComponent', field:'business_address', label: 'Corporate Office Address', placeholder: 'Enter your corporate office address'},
            { type: 'FTTextInput', label: 'Website Address', placeholder: 'eg www.google.com', field: 'website_address'},
            { type: 'FTTextInput', label: 'Corporate Email Address', placeholder: 'eg info@myoffice.com', field: 'email_address'},
            { type: 'FTTextInput', label: 'Contact Number', placeholder: 'Enter your contact number', field: 'contact_number'},
            { type: 'FTTextInput', label: 'PAN Number', placeholder: 'Enter your PAN number', field: 'pan_number'},
            { type: 'FTTextInput', label: 'GST Number', placeholder: 'Enter your GST number', field: 'gst_number'},
            { type: 'FTTextInput', label: 'CIN Number', placeholder: 'Enter your CIN number', field: 'cin_number'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/add', 'method':'SupplierCompanyDetail', 'getpoint': API_CONFIG.API_BASE_URL + 'supplier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function SupplierBankDetailScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Bank Account Details', subtitle: 'Please enter the details'},
            { type: 'FTTextInput', required: true, field: 'account_name', label: 'Account Name', error:['Please enter valid account name'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', required: true, field: 'account_number', label: 'Account Number', error:['Please enter valid account number'], validator:['isNotEmpty'] },
            { type: 'FTSelect', label: 'Account Type', field: 'account_type', options:[{l:'Savings', k:'savings'},
                {l:'Current', k:'current'}]
            },
            { type: 'FTTextInput', required: true, field: 'ifsc_code', label: 'IFSC Code', error:['Please enter valid IFSC Code'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', field:'bank_name', label: 'Bank Name', placeholder: 'eg. ICICI Bank'},
            { type: 'FTTextInput', field: 'authorised_signatory', label: 'Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email', label: 'Email' },
            { type: 'FTTextInput', field: 'phone', label: 'Phone' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/add', 'method':'SupplierBankDetail', 'getpoint': API_CONFIG.API_BASE_URL + 'supplier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function SupplierBankDetail1Screen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Other Bank Account Details', subtitle: 'Please enter the details'},
            { type: 'FTTextInput', required: true, field: 'account_name', label: 'Account Name', error:['Please enter valid account name'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', required: true, field: 'account_number', label: 'Account Number', error:['Please enter valid account number'], validator:['isNotEmpty'] },
            { type: 'FTSelect', label: 'Account Type', field: 'account_type', options:[{l:'Savings', k:'savings'},
                {l:'Current', k:'current'}]
            },
            { type: 'FTTextInput', required: true, field: 'ifsc_code', label: 'IFSC Code', error:['Please enter valid IFSC Code'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', field:'bank_name', label: 'Bank Name', placeholder: 'eg. ICICI Bank'},
            { type: 'FTTextInput', field: 'authorised_signatory', label: 'Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email', label: 'Email' },
            { type: 'FTTextInput', field: 'phone', label: 'Phone' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/add', 'method':'SupplierBankDetail1', 'getpoint': API_CONFIG.API_BASE_URL + 'supplier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function SupplierProductListScreen(props){
    return (
        <div className="flex flex-1 items-stretch overflow-hidden">
            <Gallery></Gallery>
            <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white p-8 lg:block">
                <SupplierProductDetailScreen />
            </aside>
        </div>
    )
        
}
function SupplierProductDetailScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Product Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'product_name', label: 'Product Name', error:['Please enter valid product name'], validator:['isNotEmpty'] },
            { type: 'FTComboBox', label: 'Product Category', field: 'key_products', options:[{l:'Feed', k:'feed'},{l:'Fertiliser', k:'fertiliser'},{l:'Growth & Nutrition', k:'growth_nutrition'},
                {l:'Pest & Disease', k:'pest_disease'},{l:'Equipments', k:'equipments'},{l:'Chemicals', k:'chemicals'},
                {l:'Irrigation', k:'irrigation'},{l:'Others', k:'others'}
             ]
            },
            { type: 'FTComboBox', label: 'Product Sector', field: 'key_sectors', options:[{l:'Agriculture', k:'agriculture'},
                {l:'Aquaculture', k:'aquaculture'},{l:'Poultry', k:'poultry'},{l:'Dairy', k:'dairy'},{l:'Others', k:'others'}
             ]
            },
            { type: 'FTTextAreaComponent', field:'product_description', label: 'Product Description'},
            { type: 'FTFileComponent', field:'product_file', placeholder: 'Upload your Product Catalogue'},

            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/add', 'method':'ProductId#{{id}}'}
    }
    const newProps = { ...props, 'showAsDetail': true, 'w_full': true };
    return CreateContainer({'formJSON': form, ...newProps})
        
}
export { SupplierCompanyDetailScreen, SupplierBankDetailScreen, SupplierBankDetail1Screen, SupplierProductDetailScreen, SupplierProductListScreen}