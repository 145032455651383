import { useState, useEffect} from 'react';
import { camelCaseToTitle } from './Helper';
import { ComboxBox } from './Common';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'

//id is the unique key in the data
const ListFilter = ({getCompleteList, filterBy=[], setList}) => {  
    const initialData = getCompleteList();
    //console.log("Initial data from getCompleteList:", initialData); // Debugging statement

    const [appliedFilter, setAppliedFilter] = useState({}); //Object to save selected filters
    const [myFilterMap, setMyFilterMap] = useState([])
    const [data, setData] = useState([])
    const [myData, setMyData] = useState(initialData);
    const [ids, setIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    //console.log("In MyFilter - complete list is - " + JSON.stringify(myData))
   
    //[{"id": "d71ad942-6f90-4fc7-b10d-c9a1590f5ba3", "lineSummary": "", "lineText": ["Item Name: Maida", "Item Group: Raw Material", "Hsn Code: 1700980", "Item Uom: Kgs"], 
    //"lineTitle": "", "sk": "StockItem:5a7ee96e-6e79-4531-b7d0-726d19f4c8a0"}, {"id": "e55a5bfe-791d-4da9-a8ca-af91ff036cdf", "lineSummary": "", "lineText": ["Item Name: Sugar", "Item Group: Raw Material", "Item Uom: Kgs"], "lineTitle": "", "sk": "StockItem:e7312715-0653-44d1-8952-8d9c28182b87"}]
    
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
    const  filteredData =
    searchQuery === ''
    ? myData
    : myData.filter((item) => {
        item.ATTR1 = item.ATTR1 || {};
        return Object.keys(item.ATTR1).some((key) => {
          const value = item.ATTR1[key];
          return typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase());
        });
      });
    setList(filteredData);    

    }, [searchQuery]);
    
    useEffect(() => {
        setMyData(initialData);
    }, [initialData]);

    useEffect(() => {
        //console.log("In MyFilter useEffect - ")
        let tempData = []
        let filterMap = []
        console.log("In MyFilter - myData is - " + JSON.stringify(myData))
        if(!myData || myData.length === 0 || filterBy.length === 0){
            console.log("In MyFilter - myData is empty")
            return
        }
        let allIds = []
        for(var k = 0; k < myData.length; k++){
            allIds.push(myData[k]['SK'])
        }
        setIds(allIds)
        for(var k = 0; k < myData.length; k++){
          for(var i=0; i< filterBy.length; i++){
              if(!filterMap[filterBy[i]])
                filterMap[filterBy[i]] = [];
              if(!filterMap[filterBy[i]][myData[k]['ATTR1'][filterBy[i]]])  
                filterMap[filterBy[i]][myData[k]['ATTR1'][filterBy[i]]] = [];
              filterMap[filterBy[i]][myData[k]['ATTR1'][filterBy[i]]].push(myData[k]['SK'])  
          }
        }
        filterBy?.length > 0 && filterBy.forEach((item) => {
          const label = camelCaseToTitle(item)
          if(typeof filterMap[item] === 'object'){
              tempData.push({tab: label, content: {[item]: Object.keys(filterMap[item]) } })
          }
        })
        console.log("Data is - " + JSON.stringify(tempData))
        //tempData[0] && setMyTabData(tempData[0]['tab'])  
        setData(tempData)
        setMyFilterMap(filterMap)
      }, [myData, filterBy]);
      
      
    useEffect(() => {
        if (!appliedFilter || Object.keys(appliedFilter).length === 0) {
            //setFilteredList(myData);
            return;
        }
    
        //console.log("In MyFilter useEffect - Filter is applied", JSON.stringify(appliedFilter));
        //console.log("In MyFilter useEffect - myFilterMap is - ", JSON.stringify(myFilterMap))
        //return 
      // Assuming allIds is an array containing all possible IDs
        //let allIds = [...]; // Replace with actual array of all IDs
        let filteredList = new Set(ids);

        Object.keys(appliedFilter).forEach((key) => {
            let unionSet = new Set();
            appliedFilter[key].forEach((val) => {
                myFilterMap[key][val.k].forEach((id) => {
                    unionSet.add(id);
                });
            });
            filteredList = new Set([...filteredList].filter(id => unionSet.has(id)));
        });
        filteredList = Array.from(filteredList);        
        const finalFilteredData = myData.filter(item => filteredList.includes(item['SK']));
        console.log("In MyFilter useEffect - finalFilteredData is - ", JSON.stringify(finalFilteredData))
        //setMyData(finalFilteredData);
        setList(finalFilteredData);
    }, [appliedFilter]); 
      
    
    if( typeof filterBy === 'undefined' || !Array.isArray(filterBy)){
      console.log("ListFilter - Returning empty as filterBy is empty")
      return <></>;
    }  
  
      
   
    //data={props.options} label={props.label} selectedData={filteredData} setSelectedData={handleSelectChange}
    //{"tab":"Retailer Name","content":{"retailer_name":["undefined","Retailer XX"]}}
    function handleSelectChange(field, item) { //item => {'l': 'xxxx', 'k': 'xxxx'}
        setAppliedFilter((prevData) => ({
            ...prevData,
            [field]: item
        }));
    }

    return (
        <div className="flex flex-wrap space-x-2 items-center">
            <div>
                <span className="block text-sm/6 font-medium text-gray-900">Search</span>  
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="block w-auto mt-2 rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                />
            </div>
          {data && data.length > 0 && data.map((e, index) => {
            if (!e.content || Object.keys(e.content).length === 0) {
              console.error(`not defined in e.content`);
              return <></>;
            }
            const key = Object.keys(e.content)[0]; // eg. retailer_name
            const data = e.content[key].map((item) => ({ 'l': item, 'k': item })); // Converting to format required by ComboBox
            const selectedFilter = (appliedFilter && appliedFilter[key]) ? appliedFilter[key] : [];
            const filteredData = data.filter(item =>
              selectedFilter.find(selected => selected.l === item.l && selected.k === item.k)
            );
            return (
              <div key={index} className="flex-1">
                <ComboxBox
                  data={data}
                  label={e.tab}
                  field={key}
                  selectedData={filteredData}
                  setSelectedData={handleSelectChange}
                  className="w-full border rounded"
                />
                {false && JSON.stringify(selectedFilter)}
              </div>
            );
          })}
        </div>
      );
};
export {ListFilter}
  