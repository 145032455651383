import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../store/auth-context';

const API_CONFIG = {
    get API_BASE_URL(){
      //console.log("HttpHelper - Getting API URL - " + process.env.REACT_APP_F4T_API_URL)
      return process.env.REACT_APP_F4T_API_URL
    },
    get API_GET_URL() {
      return this.API_BASE_URL + 'applicant/get';
    },
    get API_ADD_URL() {
      return this.API_BASE_URL + 'applicant/add';
    },
    get API_DELETE_URL() {
      return this.API_BASE_URL + 'applicant/delete';
    },
    get API_UPDATE_URL() {
      return this.API_BASE_URL + 'applicant/update';
    },
    get API_LIST_URL() {
      return this.API_BASE_URL + 'applicant/list';
    }
}
        
const sendPostRequest = async ({url, payload, setLoading = null, handleLogout = null}) => {
  try{
    //return {'message': 'Returnign empty object'}
      if(setLoading)
        setLoading(true)
      if(!url){
        throw new Error("URL is required")
      }
      if(!payload){
        throw new Error("Payload is required")
      }
      let headers = {'Content-Type': 'application/json'}
      if(payload?.meta?.token){
        headers['x-access-token'] = payload.meta.token.jwt
        delete payload.meta.token
      }
      //console.log("HttpHelper - Sending request to URL - " + JSON.stringify([url, payload]))
      let response = await axios.post(url, payload, {headers: headers} )
      if(setLoading)
        setLoading(false)
      //console.log("HttpHelper sendPostRequest - Received response" + JSON.stringify(response.data))
      return response.data;
    }catch(error){
      if(setLoading)
        setLoading(false)
      if (error.response) {
        if (error.response.status === 401 && handleLogout) {
          handleLogout(); 
        }
        // Server responded with a status other than 200 range
        console.error(`HttpHelper sendPostRequest - Error response: ${JSON.stringify(error.response.data)}`);
        return {'status': 'error', 'message': error.response.data.message || 'An error occurred' }
        //throw new Error(error.response.data.message || 'An error occurred');
      } else if (error.request) {
        // Request was made but no response received
        console.error(`HttpHelper sendPostRequest - No response received: ${JSON.stringify(error.request)}`);
        return {'status': 'error', 'message': 'No response received from server'}
        //throw new Error('No response received from server');
      } else {
        // Something else happened while setting up the request
        console.error(`HttpHelper sendPostRequest - Error: ${error.message}`);
        return {'status': 'error', 'message': error.message}
        //throw new Error(error.message);
      }
    }   
} 
export { sendPostRequest, API_CONFIG}