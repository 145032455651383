import { API_CONFIG } from '../../helper/HttpHelper'
import { CreateForm } from '../../component/Form'

//initiate_loan_application_review
function InitiateLoanApplicationReviewScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Initiate Loan Application Review', subtitle: 'Click on the button below to initiate the loan application review process'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/action'}
    }
    return CreateForm({'formJSON': form, ...props})
}
//completed_document_review
function CompletedDocumentReviewScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Complete Loan Review ', subtitle: 'Click on the button below to complete the loan review process'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/action'}
    }
    return CreateForm({'formJSON': form, ...props})
}

function FinaliseLoanApplicationScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Finalise Loan Application', subtitle: 'Accept or Reject the loan application. Provide the reason for rejection.'},
            { type: 'FTSelect', label: 'Accept / Reject Application', required: true, field: 'action', options:[{l:'Accept', k:'initiate_loan_offer'},
                {l:'Reject', k:'reject_loan_application'}]
            },
            { type: 'FTTextAreaComponent', field:'rejection_reason', label: 'Reason for rejection if any'},
            
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/action'}
    }
    return CreateForm({'formJSON': form, ...props})
}


export { InitiateLoanApplicationReviewScreen, CompletedDocumentReviewScreen, FinaliseLoanApplicationScreen }