import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { API_CONFIG, sendPostRequest } from '../helper/HttpHelper'
import { AuthContext } from '../store/auth-context';
import { LoadingOverlay } from "../component/Common";
import { useNavigate } from 'react-router-dom';
import {capitalizeAndSpace, formatFileSize} from '../component/Helper'


const CreateDetail = ({ formJSON, ...otherProps }) => {
  const [formData, setFormData] = useState({});
  const [httpLoading, setHttpLoading] = useState(false);
  const {token, isAuthenticated, logout, setLoanAction} = useContext(AuthContext);
  const navigate = useNavigate()

  useEffect(() => { //Fetch the data on load
    console.log("In CreateDetail - useEffect - ")
    const fetchData = async () => {
      try{
        let res = await sendPostRequest({'url': formJSON.submit.getpoint, 'payload': { 'method': formJSON.submit.method, 'meta': { 'token': token }, 'otherProps': otherProps  }, 'setLoading':setHttpLoading, 'handleLogout': logout }) 
        if(res.data){
          const obj = typeof res.data.ATTR1 === "string" ? JSON.parse(res.data.ATTR1):res.data.ATTR1 
          console.log("CreateDetail - useEffect - fetchData - Response is " + JSON.stringify(obj))
          setFormData(obj);
        }        
        //console.log("createForm - useEffect - fetchData - Response is " + JSON.stringify(res))
      }catch(e){
          console.error("CreateDetail - useEffect - fetchData " + e)
      }
    }
    if(!formJSON?.submit.getpoint){
      console.error("CreateDetail - useEffect - fetchData - getpoint not set in formJSON")
      return
    }
    isAuthenticated && token && Object.keys(token).length > 0 && fetchData()
  }, [token])  
  const handleSubmit = async () => {
    console.log("CreateDetail - handleSubmit - Detail is submitted" - JSON.stringify(otherProps))
    if (otherProps.workflow && otherProps.submit_text !== 'Back') {
      let params = { 'otherProps': otherProps }
      if(token && token.eid) //user is logged in
        params['meta'] = { 'token': token } 

      let res = await sendPostRequest({'url': API_CONFIG.API_BASE_URL + 'financier/action', 'payload': params, 'setLoading':setHttpLoading, 'handleLogout': logout })
      if(res.status === 'error'){
        console.error("In CreateDetail - handleSubmit - Error is " + res.message)
      }else{
        if(otherProps.lastFalseKey){
          console.log(' Navigating to loan detail page')
          navigate('/home/loan_detail/' + otherProps.loanId)
        } else{
          const randomNumber = Math.floor(Math.random() * 1000000);
          setLoanAction(otherProps.action + randomNumber);
        }
      }
    }else{
      navigate('/home/loan_detail/' + otherProps.loanId)
    }
  }
  function  renderElement(props, index){
    switch(props.type){
        case 'FTTextInput':
        case 'FTSelect':
        case 'FTDate':
        case 'FTTextAreaComponent':
        case 'FTDynamicSelect':        
          return FTText(props, index)
        case 'FTComboBox':        
          return FTComboBox(props, index)  
        case 'FTMultiTextInput':
          return FTMultiTextInput(props, index)  
        case 'FTFileComponent':
          return FTFileComponent(props, index)    
        default:
            return <></>
    }
  }
  function FTPageTitle(){
    if(otherProps.isContainer)
        return <></>
    const el = formJSON.body.find(element => element.field === 'form_title');
    return (
      <div className="px-4 sm:px-0 flex justify-between items-center">
        <div>
            <h3 className="text-base/7 font-semibold text-gray-900">{el?.title}</h3>
            <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">{el?.subtitle}</p>
        </div>    
      </div>
    )
  }
  function FTText(props, index) {
    const backgroundColorClass = index % 2 === 0 ? 'bg-gray-50' : '';
    return (
      <div className={`px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ${backgroundColorClass}`} key={index}>
        <dt className="text-sm/6 font-medium text-gray-900">{props.label}</dt>
        <dd className="mt-1 text-sm/6 text-gray-500 sm:col-span-2 sm:mt-0">
          {capitalizeAndSpace(formData[props.field])}
        </dd>
      </div>
    );
  }
  function FTComboBox(props, index) {
    const backgroundColorClass = index % 2 === 0 ? 'bg-gray-50' : '';
    const concatenatedLFields = formData && formData[props.field].length> 0 && formData[props.field].map(item => item.l).join(', ');
    return (
      <div className={`px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 ${backgroundColorClass}`} key={index}>
        <dt className="text-sm/6 font-medium text-gray-900">{props.label}</dt>
        <dd className="mt-1 text-sm/6 text-gray-500 sm:col-span-2 sm:mt-0">
          {concatenatedLFields}
        </dd>
      </div>
    );
  }
  function FTButton(props, index){
    return (
      <div className="pt-10 flex items-center justify-end gap-x-6 w-full" key={index}>
          <button
            type="submit"
            //Implment the save function
            onClick={() => handleSubmit()}
            className="flex w-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {otherProps.submit_text??'Next'}
          </button>
        </div>
    )
  }
  function FTMultiTextInput(props, index){
    //function DisplayFinancialData({ financialData }) {
      if(!formData || !formData[props.field])
        return <></>
      const financialData = formData[props.field];
      return (
          <div className="inline-block min-w-full py-3 align-middle sm:px-6 lg:px-8" key={index}>
              <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                      <tr className="divide-x divide-gray-200">
                          <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"></th>
                          {financialData[0] && financialData[0].values.map((quarter, index) => (
                              <th key={index} scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  {quarter.period}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                      {financialData.map((row, rowIndex) => (
                          <tr key={row.name} className="divide-x divide-gray-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                                  {row.name}
                              </td>
                              {row.values.map((value, index) => (
                                  <td key={index} className="whitespace-nowrap p-4 text-sm text-gray-500">
                                      {value.value}
                                  </td>
                              ))}
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
      );
  //}
  }
  function FTFileComponent(props, index){
    if(!formData || !formData[props.field])
      return <></>
    const files = formData[props.field];
    console.log("In FTFileComponent - " + JSON.stringify(files))
    return (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={index}>
            <dt className="text-sm/6 font-medium text-gray-900">{capitalizeAndSpace(props.field)}</dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                {files.map((file, index) => (
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6" key={index}>
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">{file['fname']}</span>
                      <span className="shrink-0 text-gray-400">{formatFileSize(file['size'])}</span>
                    </div>
                  </div>
                  <div className="ml-4 shrink-0">
                    <a href={file['url']} className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
                ))}
              </ul>
            </dd>
          </div>
    )
  }
  //if (!formData || !formData[props.field] || formData[props.field] === '') {
  //  return <></>;
  //}
  let count = 0;
  return (
    <div>
      <FTPageTitle key="1" />
      <div className="mt-6 border-t border-gray-100 w-3/5" key="2">
        <dl className="divide-y divide-gray-100 " key="1">
          { (httpLoading)?<LoadingOverlay/> : formJSON?.body.length > 0 && formJSON.body.map((props) => 
            { 
              if (!formData || !formData[props.field] || formData[props.field] === '') 
                return <></>;
              count++;
              return renderElement(props, count) 
            }
          )}
        </dl>
        {(otherProps.workflow)?<FTButton index={count+1} />:<></>}
      </div>
    </div>
  )
}
export {CreateDetail}