import { API_CONFIG } from '../../helper/HttpHelper'
import { CreateContainer } from '../../component/Container'

function FinancierCompanyDetailScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Company Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'business_name', label: 'Name of the Investor/Financial Institution'},
            { type: 'FTSelect', label: 'Type of the Investor/Financial Institution', field: 'registration_type', options:[{l:'Bank', k:'bank'},
                {l:'SFB', k:'sfb'},{l:'NBFC', k:'nbfc'},{l:'Others', k:'others'}]
            },
            { type: 'FTTextInput', field: 'year_of_incorporation', label: 'Year of establishment'},
            { type: 'FTTextInput', field: 'operating_states', label: 'Operating States'},
            { type: 'FTTextAreaComponent', field:'office_address', label: 'Corporate Office Address'},
            { type: 'FTTextInput', field: 'website_address', label: 'Website Address', placeholder: 'eg www.google.com'},
            { type: 'FTTextInput', field: 'email', label: 'Email Address'},
            { type: 'FTTextInput', field: 'phone', label: 'Contact Number'},
            { type: 'FTTextInput', field: 'pan_number', label: 'PAN Number', placeholder: 'Enter your PAN number'},
            { type: 'FTTextInput', field: 'gst_number', label: 'GST Number', placeholder: 'Enter your GST number'},
            { type: 'FTTextInput', field: 'cin', label: 'CIN Number', placeholder: 'MCA - Company Identification Number'},  
            { type: 'FTTextInput', field: 'contact_manager', label: 'Contact Manager Name'},  
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/add', 'method':'FinancierCompanyDetail', 'getpoint': API_CONFIG.API_BASE_URL + 'financier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function FinancierProduct1Screen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Product1 Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'product_name', label: 'Product Name'},
            { type: 'FTTextInput', field: 'product_description', label: 'Product Description'},
            { type: 'FTTextInput', field: 'loan_amount_min', label: 'Loan Amount (Min in Lakhs)'},
            { type: 'FTTextInput', field: 'loan_amount_max', label: 'Loan Amount (Max in Lakhs)'},
            { type: 'FTSelect', label: 'Loan Tenure ( In Months )', field: 'loan_tenure', options:[{l:'1', k:'1'},
                {l:'2', k:'2'}, {l:'3', k:'3'}, {l:'4', k:'4'}, {l:'5', k:'5'}, {l:'6', k:'6'}, {l:'7', k:'7'}, {l:'8', k:'8'},
                {l:'9', k:'9'}, {l:'10', k:'10'}, {l:'11', k:'11'}, {l:'12', k:'12'}]
            },
            { type: 'FTSelect', label: 'Interest Rate', field: 'interest_rate', options:[{l:'1.5', k:'1.5'},
                {l:'1.6', k:'1.6'}, {l:'1.7', k:'1.7'}, {l:'1.8', k:'1.8'}, {l:'1.9', k:'1.9'}, {l:'2.0', k:'2.0'}, {l:'2.1', k:'2.1'},
                {l:'2.2', k:'2.2'}, {l:'2.3', k:'2.3'}, {l:'2.4', k:'2.4'}, {l:'2.5', k:'2.5'}
                ]
            },
            //Processing Fee
            { type: 'FTTextInput', field: 'processing_fee', label: 'Processing Fee', placeholder: 'Leave empty if not applicable'},
            { type: 'FTTextInput', field: 'collateral_requirement', label: 'Collateral Requirement', placeholder: 'Leave empty if not applicable'},
            { type: 'FTTextAreaComponent', field:'loan_eligibility_criteria', label: 'Loan eligibility criteria'},
            { type: 'FTTextAreaComponent', field:'terms_conditions', label: 'Terms and Conditions'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/add', 'method':'FinancierProduct1', 'getpoint': API_CONFIG.API_BASE_URL + 'financier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function FinancierProduct2Screen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Product2 Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'product_name', label: 'Product Name'},
            { type: 'FTTextInput', field: 'product_description', label: 'Product Description'},
            { type: 'FTTextInput', field: 'loan_amount_min', label: 'Loan Amount (Min in Lakhs)'},
            { type: 'FTTextInput', field: 'loan_amount_max', label: 'Loan Amount (Max in Lakhs)'},
            { type: 'FTSelect', label: 'Loan Tenure ( In Months )', field: 'loan_tenure', options:[{l:'1', k:'1'},
                {l:'2', k:'2'}, {l:'3', k:'3'}, {l:'4', k:'4'}, {l:'5', k:'5'}, {l:'6', k:'6'}, {l:'7', k:'7'}, {l:'8', k:'8'},
                {l:'9', k:'9'}, {l:'10', k:'10'}, {l:'11', k:'11'}, {l:'12', k:'12'}]
            },
            { type: 'FTSelect', label: 'Interest Rate', field: 'interest_rate', options:[{l:'1.5', k:'1.5'},
                {l:'1.6', k:'1.6'}, {l:'1.7', k:'1.7'}, {l:'1.8', k:'1.8'}, {l:'1.9', k:'1.9'}, {l:'2.0', k:'2.0'}, {l:'2.1', k:'2.1'},
                {l:'2.2', k:'2.2'}, {l:'2.3', k:'2.3'}, {l:'2.4', k:'2.4'}, {l:'2.5', k:'2.5'}
                ]
            },
            //Processing Fee
            { type: 'FTTextInput', field: 'processing_fee', label: 'Processing Fee', placeholder: 'Leave empty if not applicable'},
            { type: 'FTTextInput', field: 'collateral_requirement', label: 'Collateral Requirement', placeholder: 'Leave empty if not applicable'},
            { type: 'FTTextAreaComponent', field:'loan_eligibility_criteria', label: 'Loan eligibility criteria'},
            { type: 'FTTextAreaComponent', field:'terms_conditions', label: 'Terms and Conditions'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'financier/add', 'method':'FinancierProduct2', 'getpoint': API_CONFIG.API_BASE_URL + 'financier/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}

export { FinancierCompanyDetailScreen, FinancierProduct1Screen, FinancierProduct2Screen }