'use client'
import { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import logo from '../assets/images/logo.png'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
   Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import { DynamicHeroIcon } from './Helper';
import { AuthContext } from '../store/auth-context';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SideBar() {
  const navigate = useNavigate()
  const {token, loading, isAuthenticated} = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  
  useEffect(() => {
    //authCtx.logout()
    //navigate('/login')  

    if(!token || token && !loading && Object.keys(token).length === 0) {
      navigate('/login')
    }
  },[token, loading, isAuthenticated])
  return (
    <>
      <div>
        { true && <NavBar menu={token?.menu} display_name={token?.display_name}></NavBar> }
        <div className="lg:pl-60">
          <ProfileDropDown display_name={token?.display_name}></ProfileDropDown>
          <main>
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="py-5">
                <Outlet />
              </div>
            </div>
          </main>     
        </div>
      </div>
    </>
  )
}

function ProfileDropDown({display_name}){
  const userNavigation = [
    { name: 'Sign out', href: 'handleLogout' },
  ]
  
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
    
  function handleLogout(){
    authCtx.logout()
    navigate('/login')    
  }
  return  <div className="sticky top-0 z-40 flex flex-row h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
              <div className="flex flex-1 flex-row-reverse gap-x-4 self-stretch lg:gap-x-6">
                  <div className="flex items-center gap-x-4 lg:gap-x-6">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">View notifications</span>
                      <DynamicHeroIcon icon={'BellIcon'} aria-hidden="true" className="h-6 w-6" />
                    </button>
                    <Menu as="div" className="relative">
                        <MenuButton className="-m-1.5 flex items-center p-1.5">
                          <span className="sr-only">Open user menu</span>
                          <span className="hidden lg:flex lg:items-center">
                          <span className="inline-block size-6 overflow-hidden rounded-full bg-gray-100">
                              <svg fill="currentColor" viewBox="0 0 24 24" className="size-full text-gray-300">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                            <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                              {`Welcome ${display_name}`}
                            </span>
                            <DynamicHeroIcon icon={'ChevronDownIcon'} aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                          </span>
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          {userNavigation.map((item) => (
                            <MenuItem key={item.name}>
                              <a
                                href={item.href}
                                onClick={(e) => {
                                  e.preventDefault()
                                  if(item.href === 'handleLogout')
                                    handleLogout()
                                }}
                                className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                              >
                                {item.name}
                              </a>
                            </MenuItem>
                          ))}
                        </MenuItems>
                    </Menu>
                  </div>
              </div>
          </div> 
}
function NavBar({menu, display_name}){
  const navigate = useNavigate()
  const [pageGroupId, setPageGroupId] = useState('')
  const [pageId, setPageId] = useState('')

  function setCurrentPage({pageId, pageGroupId}){
    setPageGroupId(pageGroupId)
    setPageId(pageId)
  }
  useEffect(() => {
    setCurrentPage({'pageGroupId': menu[0].pageGroupId,'pageId': menu[0].pages[0].pageId})
    navigate("/home/" + menu[0].pages[0].detail.path)      
  },[menu])

  console.log("NavBar - " + JSON.stringify(menu))
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-55 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div className="flex h-16 shrink-0 items-center">
          <img
            alt="Your Company"
            src="https://tailwindcss.com/plus-assets/img/logos/mark.svg?color=indigo&shade=600"
            className="h-8 w-auto"
          />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {menu.map((item, index) => (
                  <li key={item.pageGroupId}>
                    {!item.pages ? (
                      <a
                        href="#"
                        className={classNames(
                          (item.pageGroupId === pageGroupId) ? 'bg-gray-50' : 'hover:bg-gray-50',
                          'block rounded-md py-2 pl-10 pr-2 text-sm/6 font-semibold text-gray-700',
                        )}
                      >
                         <DynamicHeroIcon icon={item.detail.icon} 
                                  aria-hidden="true"
                                  className={classNames(
                                    (item.pageGroupId === pageGroupId) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0',
                                  )}
                          />
                        {item?.detail?.name}
                      </a>
                    ) : (
                      <Disclosure as="div" defaultOpen={index === 0}>
                        <DisclosureButton
                          className={classNames(
                            (item.pageGroupId === pageGroupId) ? 'bg-gray-50' : 'hover:bg-gray-50',
                            'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold text-gray-700',
                          )}
                        >
                          <ChevronRightIcon
                            aria-hidden="true"
                            className="size-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                          />
                          <DynamicHeroIcon icon={item.detail.icon} 
                                  aria-hidden="true"
                                  className={classNames(
                                    (item.pageGroupId === pageGroupId) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0',
                                  )}
                          />
                          {item?.detail?.name}
                        </DisclosureButton>
                        <DisclosurePanel as="ul" className="mt-1 px-2">
                          {item.pages.map((subItem) => (
                            <li key={subItem.pageId}>
                              <DisclosureButton
                                as="a"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentPage({'pageGroupId': item.pageGroupId, 'pageId': subItem.pageId})
                                  navigate("/home/" + subItem.detail.path)
                                }}
                                href="#"
                                className={classNames(
                                  (subItem.pageId === pageId) ? 'bg-gray-50 text-indigo-600' : 'hover:bg-gray-50',
                                  'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-gray-700',
                                )}
                              >
                                {subItem?.detail?.name}
                                {false && "Current PageId- " + pageId + "--------My PageId - " + subItem.pageId}
                              </DisclosureButton>
                            </li>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <a
                href="#"
                className="flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-gray-900 hover:bg-gray-50"
              >
                <span className="inline-block size-8 overflow-hidden rounded-full bg-gray-100">
                    <svg fill="currentColor" viewBox="0 0 24 24" className="size-full text-gray-300">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </span>
                <span>{false && JSON.stringify([pageId, pageGroupId])}</span>
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">{display_name}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}


export {SideBar}
