import { API_CONFIG, sendPostRequest } from '../../helper/HttpHelper'
import { camelCaseToTitle, classNames, timeAgo } from '../../component/Helper';
import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../store/auth-context'  
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../component/Modal'
import { LoadingOverlay, DetailFeedComponent } from '../../component/Common';
import { loanFeedJSON } from './Loan';

function LoanDetailScreen({loanId}) {
    //console.log('LoanDetailScreen - ' + loanId)
    const {token, loading, logout} = useContext(AuthContext);
    const [loanApplication, setLoanApplication] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    if(!loanId){
     loanId = params.loanId
    }
    useEffect(() => {
        const fetchLoanApplication = async () => {
          console.log("token is - ", token)
          let res = await sendPostRequest({'url': API_CONFIG.API_BASE_URL + `${token.enterprise_type}/get`, 'payload': { 'method': `LoanAppId#${loanId}`, 'meta':{token: token} }, 'setLoading':setIsLoading, 'handleLogout':logout } ) 
          console.log('LoanDetailScreen - ' + JSON.stringify(res.data))
          if(res.data && res.data){
            setLoanApplication(res.data)
            console.log(res.data)
          }
        }
        try{
          if(!loading)
            fetchLoanApplication()  
        }catch(e){
          console.error(e)
        }
      }, [])
    
    
    return (
     isLoading?<LoadingOverlay/>:
        <>
            <div className='my-4 mb-6 border-b border-gray-100'>
                <DetailFeedComponent item={loanApplication} feedJSON={loanFeedJSON} ></DetailFeedComponent>
            </div>
            {<div className="grid grid-cols-2 gap-20">
                <div className="col-span-1">
                    <StatusUpdate loanApplication={loanApplication}></StatusUpdate>
                </div>   
                <div className="col-span-1">
                    <ActionComponent loanApplication={loanApplication}></ActionComponent>  
                </div>    
            </div>}
        </> 
    )
}
function StatusUpdate({loanApplication}) {
    return (
      <>
        <ul role="list" className="space-y-6">
          {loanApplication.ATTR1 && loanApplication.ATTR1.activity && loanApplication.ATTR1.activity.length > 0 && loanApplication.ATTR1.activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === loanApplication.ATTR1.activity.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{activityItem.name}</span> {activityItem.type}
                  </p>
                  <div className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {timeAgo(activityItem.dateTime)}
                  </div>
                </>
            </li>
          ))}
        </ul>
      </>
    )
}
function ActionComponent({loanApplication}){
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({content:'', title:''})
  const {token} = useContext(AuthContext);
  const navigate = useNavigate()
  
  if(!loanApplication?.ATTR1?.workflow){
    //console.error("ActionComponent - No workflow found")
    return null
  }
  const {state, context, stages : action} = loanApplication?.ATTR1?.workflow
  if(!state || !context){
    console.error("ActionComponent - No state or context found")
    return null
  }
  console.log('ActionComponent - ' + JSON.stringify(loanApplication))
  const statuses = {
    'completed': 'text-green-700 bg-green-50 ring-green-600/20',
    'in_progress': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    'pending': 'text-gray-600 bg-blue-50 ring-gray-500/10'   
    }
  const saction = Object.keys(action).sort((a, b) => action[b].order - action[a].order)
  console.log('Sorted Action is', JSON.stringify(saction))
  return (
    <div>
      {open && <Modal title={mesg.title} content={mesg.content} action={mesg.action} closeModal={() => setOpen(false)} />}
      <ul role="list" className="divide-y divide-gray-100">
        {saction.map((key) => {
          const status = context[key] ? 'completed' : (key === state ? 'in_progress' : 'pending');
          const isFinal = action[key].final ?? false;
  
          if(status === 'pending')
            return <></>
          return (
            <li key={key} className="flex items-center justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-start gap-x-3 mb-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{action[key]['title']}</p>
                    { !isFinal && (<p
                      className={classNames(
                        statuses[status],
                        'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {camelCaseToTitle(status)}
                    </p>)}
                  </div>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{action[key]['content']}</p>
                </div>
              </div>
              {action[key]['button'] && state === key && (
                <a
                  href={`/home/loan_wf/${loanApplication?.ATTR1?.loanId}`}
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {action[key]['button']}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export { LoanDetailScreen }