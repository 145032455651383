import logo from '../assets/images/logo.png'
import { camelCaseToTitle, readableDate, formatFileSize, classNames,isObject } from './Helper';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';

function stripString(str, n = 10) {
  if (str.length > n) {
    return str.substring(0, n) + '...';
  }
  return str;
}
function LoadingOverlay(){
  return (
    <div className="flex items-center justify-center h-96">
      <svg className="w-16 h-16 text-indigo-600" viewBox="0 0 120 30" fill="currentColor">
        <circle cx="15" cy="15" r="15">
          <animate attributeName="r" from="15" to="15"
            begin="0s" dur="0.8s"
            values="15;9;15" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1"
            begin="0s" dur="0.8s"
            values="1;.5;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
          <animate attributeName="r" from="9" to="9"
            begin="0s" dur="0.8s"
            values="9;15;9" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="0.5" to="0.5"
            begin="0s" dur="0.8s"
            values=".5;1;.5" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
          <animate attributeName="r" from="15" to="15"
            begin="0s" dur="0.8s"
            values="15;9;15" calcMode="linear"
            repeatCount="indefinite" />
          <animate attributeName="fill-opacity" from="1" to="1"
            begin="0s" dur="0.8s"
            values="1;.5;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
      </svg>
    </div>
  );
}
function PreLoginHeader() {
    return (
      <header className="absolute inset-x-0 top-0 z-50">
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src={logo}
                />
              </a>
            </div>
          </nav>
      </header>
      )
}
function PreLoginLogo({title}){
  return (
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src={logo}
          className="mx-auto h-120 w-auto"
        />
        <h2 className="mt-5 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
          {title}
        </h2>
    </div>
  )
}
function TopGradient(){
  return (
      <div
      aria-hidden="true"
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
       >
          <div  
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
     </div>
  )
}
function BottomGradient(){
  return (
    <div
    aria-hidden="true"
    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
    </div>
  )
}
function PdfFileElement({ name, size, index, fileUrl }) {
  return (
    <div className="flex items-center space-x-4" key={index}>
      <a href={fileUrl} download className="flex items-center space-x-2 text-sm text-gray-500 truncate">
        <span className="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm1 18H5V4h10v16zm-9-9h6v2H6v-2zm0 4h6v2H6v-2z" fill="red" />
          </svg>
        </span>
        <div className="flex-1 min-w-0 text-sm text-gray-500 truncate">
          {stripString(name, 12)}
          <p className="text-sm text-gray-500 truncate">
            {formatFileSize(size)}
          </p>
        </div>
      </a>
    </div>
  );
}
function DetailFeedComponent({item, feedJSON, handleClick}){
  const statuses = {
  'sanctioned': 'text-green-700 bg-green-50 ring-green-600/20',
  'applying': 'text-gray-600 bg-blue-50 ring-gray-500/10',
  'under_review': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }
  console.log('LoanFeedComponent - ' + JSON.stringify(item))

  if(!item || !isObject(item) || !item.ATTR1 || !feedJSON || !feedJSON.body ){ 
      console.log("DetailFeedComponent - DetailFeedComponentCould not populate feed...invalid inputs ")
      return null
  }

  const renderChunks = (item) => {
      console.log('renderChunks - ' + JSON.stringify(item))
      const attributes = item.ATTR1 && Object.entries(item.ATTR1).filter(([key, value]) => !feedJSON.body.exclude.includes(key));
      console.log("Attributes - " + JSON.stringify(attributes)) 
      const chunkSize = 4;
      const chunks = [];
      for (let i = 0; i < attributes.length; i += chunkSize) {
        const chunk = attributes.slice(i, i + chunkSize);
        chunks.push(
          <div className="mt-1 grid grid-cols-4 text-xs gap-x-10 leading-5 text-gray-500" key={i}>
            {chunk.map(([key, value], index) => {
                    const label = camelCaseToTitle(key, true);
                    const prepend = feedJSON.body?.value_prepend?.[key] ?? '';
                    const append = feedJSON.body?.value_append?.[key] ?? '';
                    const formattedValue = feedJSON.body?.format?.[key] 
                      ? feedJSON.body.format[key](value) 
                      : value;

                    return key.includes("_file") ? (
                      <PdfFileElement name={value[0]['fname']} size={value[0]['size']} fileUrl={value[0]['url']}></PdfFileElement>
                    ) : (
                      <p className="whitespace-nowrap" key={index}>
                        <span className='text-xs mr-2'>{label}:</span>
                        <span className='text-sm font-bold truncate'>{`${prepend} ${formattedValue} ${append}`.trim()}</span>
                      </p>
                    );
            })}
          </div>
        );
      }
      return chunks;
    };
  return (
      <li key={item.sk} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 hover:cursor-pointer" onClick={() => {handleClick && handleClick({url:'/home/loan_detail/'+ item?.ATTR1?.loanId})}}>
                  <div className="min-w-0">
                      {<div className="flex items-start gap-x-3 mb-3" key="1">
                          <p className="text-sm font-semibold leading-6 text-gray-900">Loan ID: {item?.ATTR1?.loanId??item?.ATTR1?.id}</p>
                          {false && <p
                          className={classNames(
                              statuses[item?.ATTR1?.status??'applying'],
                              'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                          )}
                          >
                          {camelCaseToTitle(item?.ATTR1?.status??'applying')}
                          </p>}
                          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500" key="4">
                          <p className="whitespace-nowrap italic">
                              Last Edited {readableDate(item.modified_datetime)}
                          </p>
                          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                              <circle r={1} cx={1} cy={1} />
                          </svg>
                          <p className="truncate italic">Modified by {item?.ATTR1?.username}</p>
                          </div>
                      </div>}
                      <div className="mb-2 flex items-center">
                          <img alt="" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" 
                           className="size-12 flex-none rounded-full bg-gray-50 mr-4" />
                           <div className="flex-grow">
                            {renderChunks(item)}
                           </div>
                      </div>
                  </div>
              </li>
  )  
}
//, selectedData, setSelectedData
function ComboxBox({data, label, field, selectedData=[], setSelectedData=()=>{}}) {
  const [query, setQuery] = useState('')
  //const [selectedData, setSelectedData] = useState([])
  //let selectedData = []
  const filteredData =
    query === ''
      ? data
      : data.filter((item) => {
          return item['l'].toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      multiple   
      as="div"
      value={selectedData}
      onChange={(item) => {
        setQuery('')
        setSelectedData(field, item)
      }}
    >
      <Label className="block text-sm/6 font-medium text-gray-900">{label}</Label>
      <div className="relative mt-2">
        <ComboboxInput
          className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(item) => item['l']}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredData.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredData.map((item) => (
              <ComboboxOption
                key={item['k']}
                value={item}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                <span className="block truncate group-data-[selected]:font-semibold">{item['l']}</span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="size-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
     </Combobox>
    
  )
}
export { PreLoginHeader, TopGradient, BottomGradient, PreLoginLogo, LoadingOverlay, PdfFileElement, DetailFeedComponent, stripString, ComboxBox };
