import { API_CONFIG } from '../../helper/HttpHelper'
import { BuildList } from '../../component/List'
import { DetailFeedComponent } from '../../component/Common';
import { readableDate } from '../../component/Helper';
import { useContext } from 'react'
import { AuthContext } from '../../store/auth-context';
import { CreateContainer } from '../../component/Container';

const loanFeedJSON = {
  'body':{
      'title':'Loan Applications',
      'subtitle':'All loan applications.',
      'template':'renderFeedList',
      'format':{'performa_invoice_date':readableDate, 'target_date':readableDate },
      'value_prepend':{ 'funding_expected': 'Rs.', 'performa_invoice_amount': 'Rs.'},
      'value_append':{'expected_loan_tenure_label':'months'},
      'action':{},
      'exclude':['eid', 'supplier_eid','id', 'expected_loan_tenure', 'activity', 'loan_action', 'status', 'workflow', 'retailer_eid', 'financier_eid', 'username', 'loanId'] 
  },
  
  'fetch':{ 'method':'LoanAppId#' }
}

function ListLoanScreen() {  
    const {token} = useContext(AuthContext);
    if (!token || !token.enterprise_type) {
        console.error('Invalid token or enterprise type');
        return null;
    }
    let updatedLoanFeedJSON = {...loanFeedJSON }
    updatedLoanFeedJSON.fetch.endpoint = API_CONFIG.API_BASE_URL + `${token.enterprise_type}/list`
    return BuildList({listJSON: updatedLoanFeedJSON, FeedUI: DetailFeedComponent})    
}
function LoanOfferScreen(props){
    const {token} = useContext(AuthContext);
    if (!token || !token.enterprise_type) {
        console.error('Invalid token or enterprise type');
        return null;
    }
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Loan Offer', subtitle: 'Details of your loan offer'},
            { type: 'FTTextInput', required: true, field: 'amount_sanctioned', label: 'Amount Sanctioned( In Rs.)', placeholder: 'eg. 400000', error:['Please enter valid funding amount'], validator:['isNumeric']},
            { type: 'FTSelect', required: true,label: 'Loan Tenure( In Months)', field: 'loan_tenure', 
                options:[{l:'1', k:'1'},{l:'2', k:'2'},{l:'3', k:'3'},{l:'4', k:'4'},{l:'5', k:'5'}]
            },
            { type: 'FTSelect', required: true, label: 'Monthly rate of interest', field: 'rate_of_interest', 
                options:[{l:'1', k:'1'},{l:'1.1', k:'1.1'},{l:'1.2', k:'1.2'},{l:'1.3', k:'1.3'},{l:'1.4', k:'1.4'}]
            },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'target_date', label: 'Target Date for sanctioning Loan'},
            { type: 'FTTextAreaComponent', required: true, field:'terms', label: 'Terms and Conditions'},
            
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + `${token.enterprise_type}/add`, 'method':`LoanOffer#LoanAppId#${props.loanId}`, 'getpoint': API_CONFIG.API_BASE_URL + `${token.enterprise_type}/get`}
    }
    return CreateContainer({'formJSON': form, ...props})
}
 
export {  loanFeedJSON, ListLoanScreen, LoanOfferScreen }