import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { CreateForm } from './Form';
import { CreateDetail } from './Detail';

//Mode editMode as container, form or detail
const CreateContainer = ({ formJSON, ...otherProps }) => {
  const {token} = useContext(AuthContext);
  const [editMode, setEditMode] = useState(true); //True means form, false means detail
 
  useEffect(() => {
    if(otherProps.showAsDetail)
      setEditMode(false)
  }, [token])

  function FTPageTitle(){
    const el = formJSON.body.find(element => element.field === 'form_title');
    return (
      <div className="px-4 sm:px-0 flex justify-between items-center">
        <div>
            <h3 className="text-base/7 font-semibold text-gray-900">{el?.title}</h3>
            <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">{el?.subtitle}</p>
        </div>
        {!editMode && ( otherProps.isEditable === undefined || otherProps.isEditable ) && ( <a href="#" onClick={() => setEditMode(true)} className="text-md font-semibold text-blue-500 hover:text-blue-700">
            Edit
        </a>)}
      </div>
    )
  }
  
  //return (props?.showAsDetail?CreateDetail({'formJSON': form, ...props}):CreateForm({'formJSON': form, ...props}))
  return (
    <div>
      <FTPageTitle />
      {editMode?<CreateForm formJSON={formJSON} setEditMode={setEditMode} isContainer={true} {...otherProps} />:<CreateDetail formJSON={formJSON} isContainer={true} {...otherProps} />}
    </div>
  )
}
export {CreateContainer}