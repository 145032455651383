import { sendPostRequest, API_CONFIG } from '../../helper/HttpHelper'
import { camelCaseToTitle, readableDate, reverseFormatKeyName, readableDateTime } from '../../component/Helper';
import { BuildList } from '../../component/List'
import { Gallery } from '../../component/Gallery'

function ManageSupplierUserScreen(){
  const listJSON = {
      'body':{
        'title':'Manage Users',
        'format':{'create_datetime':readableDate, 'role':camelCaseToTitle, 'isConfirmedByAdmin':camelCaseToTitle },
        'action':[{ 'action_text':'Approve User', 'title':'Approve User!', 
          'content':'By clicking, you agree to approve the user', 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/approveUser',
          'exclude':'isConfirmedByAdmin'}, 
                  { 'action_text':'Delete User', 'title':'Delete User!', 
                   'content':'By clicking, you agree to delete the user', 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/deleteSupplierUser'}],
        
        'key_order':['username', 'role','create_datetime', 'isConfirmedByAdmin'],
        'template':'renderTableList' 
      },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/getAllUsers'}
  }
  return BuildList({listJSON: listJSON})
}
function ManageRetailerInvoiceScreen(){
  const listJSON = {
      'body':{
        'title':'Recommend Retailer Loan',
        'format':{'performa_invoice_date':readableDate, 'target_date':readableDate },
        'action':[{ 'action_text':'Recommend', 'title':'Recommend Loan!', 
          'content':'By clicking, you verify and recommend the retailer loan', 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/action',
          'method':'InvoiceVerificationCompleted'}
        ],
        'key_order': ['retailer_name',  'performa_invoice_date', 'performa_invoice_no', 'performa_invoice_amount','invoice_file', 'funding_expected', 'expected_loan_tenure', 'target_date' ],
        'value_prepend': { 'performa_invoice_amount': 'Rs. ', 'funding_expected': 'Rs. '},
        'value_append': { 'expected_loan_tenure': ' months'},
        'template':'renderTableList',
        'filter':{ 'status': 'pending_approval'},
        'filterBy':['retailer_name', 'expected_loan_tenure', 'funding_expected'] 
      },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/list', 'method': 'PerformaInvoiceVerificationRequest#LoanId:'}
  }
  return BuildList({listJSON: listJSON})
}
//return {'modified_by': username, 'modified_datetime': item.modified_datetime, 'action': item?.ATTR1?.method} 
function SupplierAuditLogScreen() {
    const listJSON = {
      'body':{
        'title':'Audit Logs',
        'subtitle':'Supplier Audit Logs',
        'filter_form':{
              'body': [
                  { type: 'FTDate', required:true, label: 'Log Date', field: 'date'},
                  { type: 'FTButton', title: 'Fetch Logs', field: 'submit_button'},
              ],
              'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/getAuditLogs'}
          },
        'template':'renderCompactList'   
      },
      'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'supplier/getAuditLogs'}
    }
    function FeedUI({props}){
      const {noun, when: modified_datetime, verb, object} = props
      return (
        <li className="py-4 px-3 text-sm text-gray-700">
              <span className="font-bold">{noun}</span>  {' ' + verb + ' ' + reverseFormatKeyName(object)}. 
              <span className="text-xs text-gray-500"> {readableDateTime(modified_datetime)}</span>
        </li>
      )
    }
    return <BuildList listJSON={listJSON}  FeedUI={FeedUI} />
}
export { ManageSupplierUserScreen, SupplierAuditLogScreen, ManageRetailerInvoiceScreen}