import { API_CONFIG } from '../../helper/HttpHelper'
import { CreateForm } from '../../component/Form'
import { BuildList } from '../../component/List'
import { readableDate } from '../../component/Helper';
import { CreateContainer } from '../../component/Container';

function ApplyLoanScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Trade Finance Loan Application', subtitle: 'Please upload your invoice and loan requirement details'},  
            { type: 'FTDynamicSelect', required: true, label: 'Supplier', field: 'supplier_eid', fetch: {'endpoint':  API_CONFIG.API_BASE_URL + 'retailer/listCommon', 'method':'EnterpriseType#supplier', 'label': 'business_name', 'key': 'eid'}},
            { type: 'FTTextInput', required: true, field: 'performa_invoice_no', label: 'Performa Invoice No' },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'performa_invoice_date', label: 'Performa Invoice Date'},
            { type: 'FTFileComponent', field:'invoice_file', placeholder: 'Upload your Performa Invoice'},
            
            { type: 'FTTextInput', required: true, field: 'performa_invoice_amount', label: 'Performa Invoice Amount(In Rs.)', placeholder: 'eg. 12345.56', error:['Please enter valid invoice amount'], validator:['isFloat']},
            { type: 'FTTextInput', required: true, field: 'funding_expected', label: 'Funding Expected Amount( In Rs.)', placeholder: 'eg. 4', error:['Please enter valid funding amount'], validator:['isNumeric']},
            { type: 'FTSelect', label: 'Expected Loan Tenure( In Months)', field: 'expected_loan_tenure', 
                options:[{l:'1', k:'1'},{l:'2', k:'2'},{l:'3', k:'3'},{l:'4', k:'4'},{l:'5', k:'5'}]
            },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'target_date', label: 'Target Date for securing Loan'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'LoanAppId#{{aid}}', 'navigate': '/home/list_loan'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function FinancialDocumentScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Financial Documents', subtitle: 'Bank transaction statement and other financial documents'},  
            { type: 'FTFileComponent', field:'bank_statements', placeholder: 'Upload your Bank Statement for last three years'},
            { type: 'FTFileComponent', field:'IT_document', placeholder: 'Upload your IT return document for last three years'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':`FinancialDocuments#LoanAppId:${props.loanId}`, 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateContainer({'formJSON': form, ...props})
}
function PerformaInvoiceVerificationRequestScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Request Supplier for Performa Verification', subtitle: 'Click on the button below to request the supplier to verify the Performa Invoice'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/action'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function InitiateLoanRequestScreen(props){
  const form = {
      'body': [
          { type: 'FTTitle',  field:'form_title', title: 'Initiate Loan Request ', subtitle: 'Click on the button below to request financier for loans. Your financial data will be shared.'},
          { type: 'FTButton', title: 'Submit', field: 'submit_button'}
      ],
      'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/action'}
  }
  return CreateForm({'formJSON': form, ...props})
}
function ReviewLoanOfferScreen(props){
    const listJSON = {
        'body':{
          'title':'Review Loan Offers',
          'format':{'target_date':readableDate },
          'action':[{ 'action_text':'Accept Loan Offer', 'title':'Accept Offer!', 
            'content':'By clicking, you agree to accept the offer shared by financier', 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/action',
            'method':'loan_offer_accepted', 'navigate': `/home/loan_detail/${props.loanId}`},
            { 'action_text':'Reject Loan Offer', 'title':'Reject Offer!', 
                'content':'By clicking, you agree to reject the offer shared by financier', 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/action',
                'method':'loan_offer_rejected', 'navigate': `/home/loan_detail/${props.loanId}`},
          ],
          'key_order': ['financier_name', 'amount_sanctioned', 'loan_tenure', 'rate_of_interest', 'target_date', 'terms'],
          'value_append': { 'loan_tenure': ' Months', 'rate_of_interest': ' %'},
          'value_prepend': { 'amount_sanctioned': 'Rs. '},
          'exclude':['financier_eid', 'loanId', 'loan_tenure_label', 'rate_of_interest_label'],
          'template':'renderTableList',
          'filter':{} 
        },
        'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/list', 'method': `LoanOffer#LoanAppId#${props.loanId}`}
    }
    return BuildList({listJSON: listJSON})
  }

 
export { ApplyLoanScreen, PerformaInvoiceVerificationRequestScreen, InitiateLoanRequestScreen, FinancialDocumentScreen, ReviewLoanOfferScreen }