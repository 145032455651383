import { API_CONFIG, sendPostRequest } from '../../helper/HttpHelper'
import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../store/auth-context'  
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { componentMapping } from '../../component/Component';
import { LoadingOverlay, DetailFeedComponent } from '../../component/Common';
import { loanFeedJSON } from './Loan';

function LoanWorkFlow({loanId}){
  //console.log('LoanWorkFlow - ' + loanId)
  const { token, logout, setPageGroupId, loanAction } = useContext(AuthContext);
  const [currentAction, setCurrentAction] = useState('');
  const [httpLoading, setHttpLoading] = useState(false);
  const [loanApplication, setLoanApplication] = useState({});
  const [state, setState] = useState('');
  const [context, setContext] = useState({});
  const [screenMap, setScreenMap] = useState([]);
  const [stages, setStages] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  let actionsState = context[`${state}_Actions`] || {};
  if (!loanId) {
      loanId = params.loanId;
  }
  function getFirstFalseKey(actions, orderArray) {
        for (const item of orderArray) {
            const key = item.action;
            if (actions[key] === false) {
                return key;
            }
        }
        return null; // Return null if no false value is found
  }
  function hasOnlyOneFalseKey(actions) {
    let falseCount = 0;
    for (const key in actions) {
        if (actions[key] === false) {
            falseCount++;
        }
    }
    return falseCount === 1;
  }
  useEffect(() => {
    console.log('In useEffect - LoanWorkFlow', loanId)
    const fetchLoanApplication = async () => {
        try {
            console.log('LoanWorkFlow - Fetching loan application - ',loanAction);
            let res = await sendPostRequest({
                'url': API_CONFIG.API_BASE_URL + `${token.enterprise_type}/get`,
                'payload': { 'method': `LoanAppId#${loanId}`, 'meta': { token: token } },
                'setLoading': setHttpLoading,
                'handleLogout': logout
            });
            console.log('Response is ', res.data);
            if (res.data) {
                setLoanApplication(res.data);
                const { stages, state, context, screenMap } = res.data?.ATTR1?.workflow || {};
                setState(state);
                setContext(context);
                setScreenMap(screenMap);
                setStages(stages);
                if(!screenMap[state] || screenMap[state].length === 0){
                    console.error('No screen map found for state ', state);
                    navigate('/home/loan_detail/' + loanId)
                    return;
                }

                console.log('getFirstFalseKey - ', state, context, screenMap[state])
                let key = screenMap[state][0].action;
                if(context[`${state}_Actions`])
                     key = getFirstFalseKey(context[`${state}_Actions`], screenMap[state]);
                if( !key){
                    console.log('No key found')
                    navigate('/home/loan_detail/' + loanId)
                    return
                }
                setCurrentAction(key);
            }
        } catch (e) {
            console.error('Failed to fetch loan application:', e);
        }
    };
    fetchLoanApplication()
      // Cleanup the timeout if the component unmounts before the timeout completes
    setPageGroupId(null);
}, [loanId, loanAction]);

  function renderAction() {
    //console.log('In renderAction - currentAction is ', currentAction)
    const element = token?.pageMap[currentAction]?.element;
    //console.log('In renderAction - element is ', element)
    let lastFalseKey = hasOnlyOneFalseKey(context[`${state}_Actions`]);
    //console.log("renderAction - screenmap state action  is ", JSON.stringify(screenMap), state, currentAction)
    let showAsDetail = false;
    let isEditable = true
    //console.log("renderAction - screenmap state action  is ", JSON.stringify(screenMap[state]),currentAction)
    if (Array.isArray(screenMap[state])) {
        for (const stateItem of screenMap[state]) {
            if (stateItem['action'] === currentAction) {
                showAsDetail = stateItem.showAsDetail ?? false;
                isEditable = stateItem.editable ?? true;
                break;
            }
        }
    }
    let submit_text = null
    if(stages[state]?.final){
        submit_text  = 'Back'
    }
    //const submit_navigate = stages[state].final ? '/home/loan_detail/' + loanId : null;
    if (element) {
        return React.createElement(componentMapping[element], { 'loanId': loanId, 'workflow': true, 'action': currentAction, 
                'state': state, 'context': context, 'lastFalseKey': lastFalseKey, 'retailer_eid': loanApplication?.ATTR1?.retailer_eid, 
                'showAsDetail': showAsDetail, 'isEditable':isEditable, 'submit_text': submit_text});
    }
    return null;
  }

  function changeCurrentAction(action){
    setCurrentAction(action)
  }
  return (
    <>
        {httpLoading ? <LoadingOverlay /> : (
            <>
                <div className='mb-2 border-b border-gray-100'>
                    <DetailFeedComponent item={loanApplication} feedJSON={loanFeedJSON} />
                </div>
                <div className="flex px-4 py-6 w-full">
                    <div className="flex-[2_2_0%]">
                        <nav aria-label="Progress" className="flex">
                            <ol role="list" className="space-y-6">
                                {screenMap[state] && screenMap[state].length > 0 && screenMap[state].map((step, index) => {
                                    const actionName = token.pageMap?.[step.action]?.name;
                                    if (!actionName){
                                        console.error('Action name not found in menu for ', step.action);
                                        return null;
                                    }
                                         
                                    const isCurrentStep = step.action === currentAction;
                                    const isCompleted = actionsState[step.action];
                                    return (
                                        <li key={index}>
                                            {isCompleted ? (
                                                <a onClick={(e) => {
                                                    e.preventDefault();
                                                    changeCurrentAction(step.action);
                                                }} className="group cursor-pointer">
                                                    <span className="flex items-start">
                                                        <span className="relative flex size-5 shrink-0 items-center justify-center">
                                                            <CheckCircleIcon
                                                                aria-hidden="true"
                                                                className="size-full text-indigo-600 group-hover:text-indigo-800"
                                                            />
                                                        </span>
                                                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                            {actionName}
                                                        </span>
                                                    </span>
                                                </a>
                                            ) : isCurrentStep ? (
                                                <a href="#" aria-current="step" className="flex items-start">
                                                    <span aria-hidden="true" className="relative flex size-5 shrink-0 items-center justify-center">
                                                        <span className="absolute size-4 rounded-full bg-indigo-200" />
                                                        <span className="relative block size-2 rounded-full bg-indigo-600" />
                                                    </span>
                                                    <span className="ml-3 text-sm font-medium text-indigo-600">
                                                        {actionName}
                                                    </span>
                                                </a>
                                            ) : (
                                                <a onClick={(e) => {
                                                    e.preventDefault();
                                                    changeCurrentAction(step.action);
                                                }} className="group cursor-pointer">
                                                    <div className="flex items-start">
                                                        <div aria-hidden="true" className="relative flex size-5 shrink-0 items-center justify-center">
                                                            <div className="size-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                                                        </div>
                                                        <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                            {actionName}
                                                        </p>
                                                    </div>
                                                </a>
                                            )}
                                        </li>
                                    );
                                })}
                            </ol>
                        </nav>
                    </div>
                    <div className="flex-[8_8_0%]">
                        {renderAction()}
                    </div>
                </div>
            </>
        )}
    </>
);

}
export { LoanWorkFlow }