import { ApproveEnterpriseScreen, ListRetailerScreen, ListSupplierScreen, ListFinancierScreen } from '../screen/admin/ManageEnterprise';
import { SupplierCompanyDetailScreen, SupplierBankDetailScreen, SupplierBankDetail1Screen,SupplierProductListScreen,SupplierProductDetailScreen } from '../screen/supplier/Profile';
import { ManageSupplierUserScreen, SupplierAuditLogScreen, ManageRetailerInvoiceScreen } from '../screen/supplier/Manage';
import { RetailerBusinessDetailScreen, RetailerBankDetailScreen, RetailerBankDetail1Screen,RetailerGstAccessScreen, RetailerITAccessScreen } from '../screen/retailer/Profile';
import { ListLoanScreen, LoanOfferScreen } from '../screen/common/Loan.js'
import { FinancialDocumentScreen, InitiateLoanRequestScreen, PerformaInvoiceVerificationRequestScreen, ApplyLoanScreen, ReviewLoanOfferScreen } from '../screen/retailer/Loan.js';
import { FinancierCompanyDetailScreen, FinancierProduct1Screen, FinancierProduct2Screen } from '../screen/financier/Profile';
import { BasicInfoScreen, QuarterlyInfoScreen, AnnualInfoScreen } from '../screen/retailer/Financial';
import { LoanWorkFlow }  from '../screen/common/LoanWorkflow.js';
import { LoanDetailScreen }  from '../screen/common/LoanDetail.js';
import {InitiateLoanApplicationReviewScreen, CompletedDocumentReviewScreen, FinaliseLoanApplicationScreen} from '../screen/financier/Loan.js';

const componentMapping = {
  SupplierProductListScreen:SupplierProductListScreen,
  SupplierProductDetailScreen:SupplierProductDetailScreen,
  ReviewLoanOfferScreen:ReviewLoanOfferScreen,
  LoanOfferScreen: LoanOfferScreen,
  FinaliseLoanApplicationScreen:FinaliseLoanApplicationScreen,
  CompletedDocumentReviewScreen:CompletedDocumentReviewScreen,
  InitiateLoanApplicationReviewScreen:InitiateLoanApplicationReviewScreen,
  FinancialDocumentScreen:FinancialDocumentScreen,
  InitiateLoanRequestScreen:InitiateLoanRequestScreen,
  PerformaInvoiceVerificationRequestScreen:PerformaInvoiceVerificationRequestScreen,
  RetailerGstAccessScreen: RetailerGstAccessScreen,
  RetailerITAccessScreen: RetailerITAccessScreen,
  LoanWorkFlow: LoanWorkFlow,
  LoanDetailScreen:LoanDetailScreen,
  AnnualInfoScreen:AnnualInfoScreen,
  QuarterlyInfoScreen:QuarterlyInfoScreen,
  BasicInfoScreen:BasicInfoScreen,
  SupplierCompanyDetailScreen:SupplierCompanyDetailScreen, 
  SupplierBankDetailScreen:SupplierBankDetailScreen,
  SupplierBankDetail1Screen:SupplierBankDetail1Screen,
  RetailerBusinessDetailScreen:RetailerBusinessDetailScreen,
  RetailerBankDetailScreen:RetailerBankDetailScreen,
  RetailerBankDetail1Screen:RetailerBankDetail1Screen,
  FinancierCompanyDetailScreen: FinancierCompanyDetailScreen,
  FinancierProduct1Screen: FinancierProduct1Screen,
  FinancierProduct2Screen: FinancierProduct2Screen,
  ListLoanScreen: ListLoanScreen,
  ApplyLoanScreen: ApplyLoanScreen,
  ApproveEnterpriseScreen: ApproveEnterpriseScreen,
  ListRetailerScreen: ListRetailerScreen,
  ListSupplierScreen: ListSupplierScreen,
  ManageSupplierUserScreen: ManageSupplierUserScreen,  
  ListFinancierScreen: ListFinancierScreen,
  SupplierAuditLogScreen: SupplierAuditLogScreen,
  ManageRetailerInvoiceScreen: ManageRetailerInvoiceScreen
};

export { componentMapping };